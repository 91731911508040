<template>
  <div>
    <contents />
  </div>
</template>

<script>
import Contents from '@/components/InternalRecords/Content/Contents.vue'

export default {
  components: { Contents },
  name: 'Content',
}
</script>
