var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","transition":"dialog-transition","width":"700","height":"180"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"11"}},[_c('h6',{staticClass:"text-subtitle-1 font-weight-bold text-center"},[_c('span',{staticClass:"font-work-sans ml-5"},[_vm._v(_vm._s(_vm.edit ? 'Modificar ' : 'Crear ')+"Contenido")])])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function () {
                  _vm.contentData.image = null
                  _vm.$refs.form.reset()
                  _vm.$emit('dismiss')
                }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-times")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"ml-4 black--text font-weight-medium"},[_vm._v("Nombre")]),_c('v-text-field',{staticClass:"mt-2 mb-n3",attrs:{"placeholder":"Nombre","color":"primary","rounded":"","rules":[_vm.rules.required, _vm.rules.alfanumeric],"required":"","outlined":""},model:{value:(_vm.contentData.name),callback:function ($$v) {_vm.$set(_vm.contentData, "name", $$v)},expression:"contentData.name"}}),_c('label',{staticClass:"ml-4 black--text font-weight-medium"},[_vm._v("Descripción")]),_c('v-textarea',{staticClass:"mt-2 mb-n3",attrs:{"placeholder":"Descripción","color":"black","rounded":"","rules":[_vm.rules.alfanumeric],"solo":"","rows":"5"},model:{value:(_vm.contentData.description),callback:function ($$v) {_vm.$set(_vm.contentData, "description", $$v)},expression:"contentData.description"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"ml-4 black--text font-weight-medium"},[_vm._v("Imagen")]),_c('v-file-input',{staticClass:"mt-2 mb-n3 font-quicksand",attrs:{"rules":[_vm.requiredImage],"accept":"image/png, image/jpeg, image/bmp","placeholder":"Imagen","color":"primary","prepend-icon":"","prepend-inner-icon":"mdi-camera","required":"","outlined":"","rounded":""},on:{"change":_vm.setImage},model:{value:(_vm.contentData.imagen),callback:function ($$v) {_vm.$set(_vm.contentData, "imagen", $$v)},expression:"contentData.imagen"}}),_c('v-img',{staticClass:"mx-auto",attrs:{"src":_vm.contentData.image,"max-width":"80%"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mt-n4"},[_c('v-btn',{staticClass:"rounded-lg px-4",attrs:{"color":"primary","outlined":""},on:{"click":function () {
              _vm.contentData.image = null
              _vm.$refs.form.reset()
              _vm.$emit('dismiss')
            }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-times")]),_c('span',{staticClass:"text-capitalize ml-2 font-weight-bold"},[_vm._v("Cancelar")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"rounded-lg elevation-0 px-4",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.submit}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-check")]),_c('span',{staticClass:"text-capitalize ml-2 font-weight-bold"},[_vm._v("Aceptar")])],1)],1)],1)],1),_c('dialog-edit',{attrs:{"item":_vm.item,"dialog":_vm.dialogEdit},on:{"dismiss":function () {
        _vm.dialogEdit = false
      },"accept":function($event){return _vm.activeAlerts()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }