<template>
  <div>
    <v-dialog
      v-model="isActive"
      persistent
      transition="dialog-transition"
      width="700"
      height="180"
    >
      <v-card class="rounded-lg">
        <v-card-title>
          <v-row>
            <v-col cols="11" class="px-0">
              <h6 class="text-subtitle-1 font-weight-bold text-center">
                <span class="font-work-sans ml-5"
                  >{{ edit ? 'Modificar ' : 'Crear ' }}Contenido</span
                >
              </h6>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-btn
                color="primary"
                icon
                @click="
                  () => {
                    contentData.image = null
                    $refs.form.reset()
                    $emit('dismiss')
                  }
                "
                ><v-icon small>fa-times</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <label class="ml-4 black--text font-weight-medium"
                  >Nombre</label
                >
                <v-text-field
                  placeholder="Nombre"
                  color="primary"
                  rounded
                  :rules="[rules.required, rules.alfanumeric]"
                  required
                  outlined
                  class="mt-2 mb-n3"
                  v-model="contentData.name"
                ></v-text-field>

                <label class="ml-4 black--text font-weight-medium"
                  >Descripción</label
                >
                <v-textarea
                  placeholder="Descripción"
                  color="black"
                  rounded
                  :rules="[rules.alfanumeric]"
                  v-model="contentData.description"
                  solo
                  rows="5"
                  class="mt-2 mb-n3"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <label class="ml-4 black--text font-weight-medium"
                  >Imagen</label
                >
                <v-file-input
                  :rules="[requiredImage]"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Imagen"
                  color="primary"
                  prepend-icon=""
                  prepend-inner-icon="mdi-camera"
                  required
                  outlined
                  rounded
                  class="mt-2 mb-n3 font-quicksand"
                  v-model="contentData.imagen"
                  @change="setImage"
                ></v-file-input>

                <v-img
                  :src="contentData.image"
                  max-width="80%"
                  class="mx-auto"
                ></v-img>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-n4">
          <v-btn
            color="primary"
            outlined
            class="rounded-lg px-4"
            @click="
              () => {
                contentData.image = null
                $refs.form.reset()
                $emit('dismiss')
              }
            "
          >
            <v-icon small>fa-times</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            class="rounded-lg elevation-0 px-4"
            @click="submit"
            :loading="loading"
          >
            <v-icon small>fa-check</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-edit
      :item="item"
      :dialog="dialogEdit"
      @dismiss="
        () => {
          dialogEdit = false
        }
      "
      @accept="activeAlerts()"
    />
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
import Swal from 'sweetalert2'
import DialogEdit from '@/components/DialogEdit.vue'
const ContentRepository = Repository.get('contents')

export default {
  components: { DialogEdit },
  name: 'FormDialogContent',
  props: {
    content: {
      type: Object,
      default: () => ({ id: '', name: '', description: '', image: null }),
    },
    dialog: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
    valid: false,
    loading: false,
    contentData: {
      id: '',
      name: '',
      description: '',
      image: '',
      imagen: null,
    },
    dialogEdit: false,
    item: {},
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      alfanumeric: (value) => {
        const pattern = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
        return !pattern.test(value) || 'Campo inválido.'
      },
      integer: (value) => {
        const pattern = /[^0-9]/
        return !pattern.test(value) || 'Campo inválido.'
      },
    },
  }),
  watch: {
    content: {
      handler(newValue, oldValue) {
        if (newValue) this.contentData = Object.assign({}, newValue)
      },
      deep: true,
    },
    dialog: {
      handler(newValue, oldValue) {
        this.isActive = newValue
      },
      deep: true,
    },
  },
  methods: {
    submit: function () {
      this.$refs.form.validate()

      setTimeout(() => {
        if (this.valid) {
          if (!this.edit) this.activeAlerts()
          else {
            this.item = {
              type: 'Cn',
              id: this.content.id,
              name: this.content.name,
            }
            this.dialogEdit = true
          }
        }
      })
    },

    activeAlerts: async function () {
      this.dialogEdit = false

      const data = {
        type: 'success',
        title: 'Petición exitosa',
        text: this.edit
          ? 'Contenido actualizado con éxito'
          : 'Contenido registrado con éxito',
      }

      this.loading = true

      try {
        const value = new FormData()
        value.append('id', this.contentData.id)
        value.append('name', this.contentData.name)
        value.append('description', this.contentData.description)

        if (this.contentData.imagen)
          value.append('image', this.contentData.imagen)
        else value.append('image', this.contentData.image)

        this.edit
          ? await ContentRepository.update(value, this.contentData.id)
          : await ContentRepository.create(value)

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$refs.form.reset()
        this.contentData.image = null
        this.$emit('dismiss')
      } catch (error) {
        const response = error.response
          ? error.response.data.data ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },

    setImage: function () {
      if (this.contentData.imagen)
        this.contentData.image = URL.createObjectURL(this.contentData.imagen)
    },

    requiredImage: function () {
      return !!this.contentData.image || 'Campo requerido.'
    },
  },
}
</script>
